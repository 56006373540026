import type { ReactElement } from 'react';
import RecipeFilters from '../../models/RecipeFilters';

function FeatureHighlights(): ReactElement {
  const navigate = (url: string) => {
    window.location.href = url;
  };

  // Render feature highlight goals based on json list for potential
  // modifications of passing user specific goal in as utm param
  const goalJSON = {
    goals: '["high-protein", "gluten-free", "less-than-600-calories", "low-sodium", "quick-and-easy", "grocery"]',
  };

  const goals = JSON.parse(goalJSON.goals);

  return (
    <div className="c-feature-highlights">
      <h2 className="c-feature-highlights__title">
        {'What\'s your goal?'}
      </h2>
      <div className="slider-container">
        <div className="slider">
          {goals
            .map((goal) => {
              const recipeFilter = RecipeFilters.getPreferenceBySlug(goal);
              return (
                <div className="slide" key={recipeFilter.key}>
                  <div className="slide-content">
                    <div className="slide-image">
                      <img className="c-img" src={recipeFilter.imageUrl} alt={recipeFilter.key} />
                      <div className="label-background" />
                      <div className="slide-label">{recipeFilter.goalBlurb}</div>
                    </div>
                    <button className="slide-button" type="button" onClick={() => navigate(recipeFilter.url)}>
                      Shop
                      {' '}
                      {recipeFilter.label}
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="c-lettuce-animation">
        <div className="c-img-container" />
      </div>
    </div>
  );
}

export default FeatureHighlights;
